<template>
  <div
    class="header fixed w-full py-8 z-50 text-white transition-all duration-200"
    :class="{minimal: !isHome}"
    ref="header"
  >
    <div class="container">
      <div class="flex justify-between items-center">
        <nuxt-link to="/" class="">
          <svg-icon
            v-if="isHome"
            name="logo"
            class="fill-current w-36 md:w-44 h-8"
          />

          <div v-else class="w-36 md:w-44 h-8 overflow-hidden rounded-lg">
            <app-image
              src="/images/logo.webp"
              ratio="5-2"
              class="h-full"
              fit="contain"
            />
          </div>
        </nuxt-link>
        <div class="lg:flex justify-between items-center gap-x-2 hidden">
          <nuxt-link to="/projects" class="font-semibold py-2 px-6"
            >أعمالنا</nuxt-link
          >
          <nuxt-link to="/articles" class="font-semibold py-2 px-6"
            >مقالات</nuxt-link
          >
          <!-- <nuxt-link to="/career" class="font-semibold py-2 px-6"
            >وظائف</nuxt-link
          > -->
        </div>

        <nuxt-link
          to="/contactus"
          class="btn flex text-sm lg:text-base px-4 lg:px-6 items-center justify-center gap-x-3"
        >
          تواصل معنا
          <svg-icon name="send" class="fill-current w-8 h-4" />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {throttle} from "lodash-es";
const header = ref();

const $route = useRoute();

const isHome = computed(() => {
  return $route?.path === "/";
});

onMounted(() => {
  window.addEventListener(
    "scroll",
    throttle((e) => {
      if (window?.scrollY > (header.value?.clientHeight || 0) / 2) {
        header.value?.classList.add("active");
      } else {
        header.value?.classList.remove("active");
      }
    }, 100)
  );
});
</script>

<style lang="postcss" scoped>
.header {
  &.minimal {
    @apply py-4 text-gray-500 bg-white;
  }
  &.active {
    @apply bg-white text-gray-500 py-4 shadow-xl shadow-gray-100/25;
  }
}
</style>
