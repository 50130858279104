<template>
  <div>
    <div class="relative bg-sky-200 pt-16 pb-10 overflow-hidden">
      <div class="container footer-content relative">
        <shimmer-footer v-if="contactsLoading" />
        <div v-else class="sm:flex md:justify-between flex-wrap gap-x-10">
          <div class="mb-6">
            <nuxt-link to="/" title="The Future">
              <img src="/images/logo.webp" alt="logo" class="w-50 h-10" />
            </nuxt-link>

            <AppSocial
              class="mt-4"
              :instagram="contacts?.instagram"
              :tiktok="contacts?.tekTok"
              :facebook="contacts?.facebook"
            />
          </div>

          <div class="mb-6">
            <h3 class="text-lg lg:text-xl font-bold mb-6">ابق على تواصل</h3>
            <a
              :href="`tel:${contacts?.phone}`"
              class="block text-base lg:text-lg py-2"
              >{{ contacts?.phone }}
            </a>
            <nuxt-link
              :href="`mailto:${contacts?.email}`"
              class="block text-base lg:text-lg py-2"
              >{{ contacts?.email }}
            </nuxt-link>
          </div>

          <div class="mb-6">
            <h3 class="text-lg lg:text-xl font-bold mb-6">نبذه عنا</h3>
            <nuxt-link to="/contactus" class="block text-base lg:text-lg py-2"
              >تواصل معنا
            </nuxt-link>
            <nuxt-link to="/projects" class="block text-base lg:text-lg py-2"
              >أعمالنا
            </nuxt-link>
            <nuxt-link to="/#services" class="block text-base lg:text-lg py-2"
              >خدماتنا
            </nuxt-link>
          </div>

          <div class="mb-6">
            <h3 class="text-lg lg:text-xl font-bold mb-6">خدماتنا</h3>
            <template v-if="services?.length">
              <nuxt-link
                v-for="item in services"
                :key="item?.id"
                to="/#services"
                class="block text-base lg:text-lg py-2"
                >{{ item?.title }}
              </nuxt-link>
            </template>
          </div>
        </div>
      </div>

      <img
        src="/images/footer-bg.webp"
        alt="footer texture"
        class="footer-bg absolute -bottom-10 start-0"
        fetchPriority="low"
      />
    </div>

    <div class="bg-white">
      <div class="container">
        <div class="flex flex-wrap items-center justify-between py-6">
          <p class="fs-sm">Made For TheFuture</p>
          <p class="fs-sm">© {{ currentYear }} TheFuture</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const currentYear = computed(() => {
  return new Date().getFullYear();
});

const {contacts, loading: contactsLoading} = useContacts();

const {services} = useService();
</script>

<style lang="postcss" scoped>
.footer {
  &-content {
    z-index: 2;
  }
  &-bg {
    z-index: 1;
  }
}
</style>
